import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { KycTreeComponent } from './kyc-tree';
import { camelCaseToCapitalizedWords } from 'utils';
import { KycComperhensiveSummaryDetailsState } from 'global-stores';
import { ComprehensiveHeader } from 'views/sessions/components/kyb-wrapper/components/kyb-comprehensive/components/comprehensive-header';

export const KycTreeComponentWrapper = () => {
	const kycComperhensiveSummaryDetails = useRecoilValue(
		KycComperhensiveSummaryDetailsState
	);
	const getFlowChart = useMemo(() => {
		return (
			<div className="kyc-comprohensive-chart__wrapper">
				{Object.entries(kycComperhensiveSummaryDetails ?? {}).map(
					([sectionHeading, sectionData]) => (
						<KycTreeComponent
							key={sectionHeading}
							sectionHeading={camelCaseToCapitalizedWords(sectionHeading)}
							sectionData={sectionData}
						/>
					)
				)}
			</div>
		);
	}, [kycComperhensiveSummaryDetails]);

	return (
		<>
			<ComprehensiveHeader label={'Kyc Comperhensive Summary'} />
			<div className="kyc-comprohensive-chart"> {getFlowChart}</div>
		</>
	);
};
