/* eslint-disable no-mixed-spaces-and-tabs */
import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { kycSettingsState } from 'global-stores';
import { useNotification } from 'hooks';
import { MESSAGE } from 'constant';
import { ITreeGraphChart } from '../type';
import { sessionActionStatus } from '../components';

export const useTreeGraphChart = () => {
	const kycSettings = useRecoilValue(kycSettingsState);
	const { settings } = useMemo(() => kycSettings, [kycSettings]);
	const { bodyFont } = settings ?? {};

	const { errorNotification } = useNotification();

	const getTreeGraphConfig = useCallback(
		({
			isLoading,
			seriesData,
			title,
			isInverted,
			seriesLabelName,
			nodesColor,
			isInvite,
			isRecipientInvite,
			overlayTemplateIds,
			onClick,
			isSessionModal,
			isStepper,
			activeStepId,
			getPricingData,
		}: ITreeGraphChart) => {
			const dynamicHeight = seriesData.length == 1 ? 150 : seriesData.length > 4 ? seriesData.length * 80 : 250;
			try {
				const chart =
					(window as any).Highcharts?.chart(title, {
						chart: {
							inverted: isInverted,

							height: !isStepper ?
								isInvite || isSessionModal
									? seriesData.length === 1
										? 500
										: dynamicHeight
									: seriesData.length > 6
										? 150
										: seriesData.length === 1
											? 34
											: 76 : dynamicHeight,

							backgroundColor: 'transparent',
							style: {
								fontFamily:
									bodyFont?.family !== '' ? bodyFont?.family : 'Poppins',
							},

							overflow: 'auto',
							scrollbar: {
								enabled: true,
							},

							width: !isStepper ?
								isInvite || isSessionModal
									? seriesData.length > 2
										? seriesData.length > 5
											? seriesData.length * 240
											: seriesData.length * 310
										: 700
									: seriesData.length > 2
										? seriesData.length * 110
										: seriesData.length === 1
											? 202
											: 330 : seriesData.length < 10 ? 960 : seriesData.length < 15 ? seriesData.length * 120 : seriesData.length * 100,

							marginTop:
								!isStepper ? isInvite || isSessionModal
									? seriesData.length === 1
										? 220
										: 100
									: seriesData.length > 6
										? 40
										: 0 : 50,

							marginBottom: 0,
							marginRight: !isStepper ? 0 : 170,

							marginLeft:
								!isStepper ? isInvite || isSessionModal
									? seriesData.length === 1 && seriesData[0]?.length === 1
										? 270
										: 50
									: 0 : 50,
						},

						credits: {
							enabled: false,
						},
						title: {
							text: null,
						},

						exporting: { enabled: false },

						accessibility: {
							enabled: false,
						},

						plotOptions: {
							series: {
								states: {
									inactive: {
										opacity: 1,
									},
								},
							},
						},

						tooltip: {
							//@shivangi to show the tooltip on each nd every node in CF
							outside: false,
							useHTML: true,
							formatter: function (this: any) {
								if (this.point.formatPrefix === 'node') {
									return seriesLabelName(this.key);
								} else {
									return `${seriesLabelName(
										this.point.fromNode.id
									)} →  ${seriesLabelName(this.point.toNode.id)}`;
								}
							},
							positioner: isStepper && function (...params: Array<any>) {
								return { x: params[2].plotX, y: params[2].plotY + 60 };
							},
							fontSize: 5,
							style: {
								fontWeight: 600,
								textOutline: 'none',
							},

							crop: false,
							overflow: 'auto',
							delayForDisplay: 0,
							hideDelay: 0,
							split: true,
							shape: 'square',
						},

						series: [
							{
								type: 'organization',
								keys: ['from', 'to', 'linkColor'],
								data: seriesData,
								nodes: nodesColor,
								nodeWidth:
									isInvite || isSessionModal
										? 182
										: seriesData.length === 1 && seriesData[0]?.length === 1
											? 182
											: 70,

								height: isInvite || isSessionModal ? 60 : 30,
								colorByPoint: false,
								color: '#8c97b8',
								borderRadius: 0,
								animation: false,
								linkLineWidth: 2,

								dataLabels: {
									color: '#ffffff',
									nodeFormatter: function (this: any) {
										const color = this.point.color;
										const isConfigured =
											color === sessionActionStatus.completed;
											//Ravi Prajapat: commented this code as it is creating issue, discussed same with paras he will look for alternative solution.
											// return isInvite  && seriesLabelName(this.key) === 'Sign Doc.' 
										return isInvite 
											? `<div style="font-weight:500;font-size:12px;display:flex;flex-direction:column;justify-content: center;align-items:center;gap:8px;height:100%; width: 174px;"> 

										<div style=" width: 174px;white-space: nowrap;
										text-overflow: ellipsis;text-align:center;">${seriesLabelName(this.key)} </div>
											
											${seriesLabelName(this.key) === 'Sign Doc.' 
												? `${isRecipientInvite ? overlayTemplateIds?.includes(this.point.id) ?
												`<div style="font-weight:500;text-align:center;font-size:12px;background-color:#ffffff;cursor:pointer;width:174px;color:blue;padding:2px 0px;">
												Recipients Detail</div>` : '' :	
												`<div style="font-weight:500;text-align:center;font-size:12px;background-color:#ffffff;cursor:pointer;width:174px;color:blue;padding:2px 0px;">
												${isConfigured ? 'Re-upload' : 'Upload'} </div>`
											}
											</div>`
												: ``
											}
											${seriesLabelName(this.key) === 'Event'
												? `	<div style="font-weight:500;text-align:center;font-size:12px;background-color:#ffffff;cursor:pointer;width:174px;color:blue;padding:2px 0px;">${isConfigured ? 'Re-configured' : 'Configure'
												}</div>
											</div>`
												: ``
											}
											${seriesLabelName(this.key) === "Proof Reading" || seriesLabelName(this.key) === "Document Review"
												? `	<div style="font-weight:500;text-align:center;font-size:12px;background-color:#ffffff;cursor:pointer;width:174px;color:blue;padding:2px 0px;">${isConfigured ? 'Re-upload' : 'Upload'
												}</div>
											</div>`
												: ``
											}
											${seriesLabelName(this.key) === 'Fund (Pay In)' ||
												seriesLabelName(this.key) === 'Fund (Pay Out)'
												? `	<div style="font-weight:500;text-align:center;font-size:12px;background-color:#ffffff;cursor:pointer;width:174px;color:blue;padding:2px 0px;">${isConfigured ? 'Re-configure' : 'Configure'
												}</div>
											</div>`
												: ``
											}
									
									`
											: `<div style="font-weight:500;font-size:12px;display: flex;align-items: center;text-align:center;width:100%;height:100%"> 
									<div id="${isStepper && activeStepId == this.key ? 'activeStep' : seriesLabelName(this.key)}" style="text-align:center;width:100%;color:${isStepper && activeStepId == this.key ? '#0051CC' : ''}">
									${!isStepper ? seriesLabelName(this.key) : `<div style="overflow: hidden; text-align: center;">${seriesLabelName(this.key)}</div>${getPricingData(this.key)}`} <div>
								</div>
							`;
									},
									style: {
										fontWeight: 500,
										fontSize: 1,
										textOutline: 'none',
										textOverflow: 'ellipsis',
									},
								},

								point: {
									events: {
										click: function (event: any) {
											event.preventDefault();
											onClick?.(event.point.id);
										},
									},
								},
							},
						],
					}) ?? {};

				if (isLoading) {
					chart.showLoading();
				} else {
					chart.hideLoading();
				}
			} catch (error) {
				errorNotification(MESSAGE.ERROR);
			} finally {
				const activeId = isStepper ? 'activeStep' : 'Biometric';
				setTimeout(() => {
					document.getElementById(activeId)?.scrollIntoView(
							{
								behavior: "smooth",
								block: "center",
								inline: "center",
							}
						)
				}, 100);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[bodyFont?.family]
	);

	return { getTreeGraphConfig };
};
