import {
	Confirmation,
	IconBtn,
	NoDataAvailable,
	ReactResponsiveTable,
} from '@storybook';
import Modal from '@storybook/new-modal/modal';
import Tippy from '@tippyjs/react';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';

import { API_URL, MESSAGE } from 'constant';
import {
	AllConditionalPipelinesState,
	AllPipelinesState,
	BusinessPaymentCardState,
	ClientsCredentialsState,
	CredentialsLoadedState,
	FundPaginationState,
	OpenInviteSelectorModal,
	PipelineIdState,
	PipelinesTableRow,
	SelectedDateRangeState,
	SelectedOnboardingAction,
	SelectedPipelineDetails,
	SelectedPipelineState,
	loginState,
} from 'global-stores';
import {
	useAllowedInvites,
	useFreePlan,
	useNetwork,
	useNotification,
	useSubscription,
} from 'hooks';
import { ROUTES } from 'routes';
import {
	OnboardingTreeGraph,
	SessionTreeGraph,
	useComplexLayout,
} from 'views/complex-onboarding-flow';
import { DashboardsAtom } from 'views/dashboard-analytics';
import { WelcomeNewUserModalState } from 'views/new-user-popup';
import {
	AddedActionsState,
	IsConfigurationChecking,
	OnboardingFooterNavigateState,
	SelectedStepsState,
	UnConfiguredStep,
} from 'views/onboarding-flow/store';
import { PERMISSION_SCREEN, useUserRoles } from 'views/routes-children';
import { useBusinessCards } from 'views/settings';
import { envHost } from '../../helpers/env-url';
import {
	DropOptions,
	OnboardingQRFlow,
	OnboardingQRModalNavigateState,
	OnboardingQRModalOpenState,
	OnboardingQRTable,
	SelectedPipelineDetailsForQRState,
	ViewPipeline,
	useOnboardingQrFlow,
} from './components';
import { ComplexStepsView } from './components/complex-step-view';
import { OnboardingInviteModal } from './components/onboarding-invite-modal';
import {
	ConditionalOnboardingFlowState,
	IsQrInviteState,
} from './components/store';
import {
	ErrorSessionsNotWrite,
	PipelinesTable,
	actionKeyLabel,
} from './constants';
import { getOffDate , useDeepCompareEffect,getDate,getTime } from 'utils';

import './pipelines.scss';
import { ComplexStepsRecipientInviteView } from './components/complex-step-recipient-invite';
import { IsComplexStepRecipientInviteModalOpen } from 'views/multi-sign-agreement';
import { EventsState, useEvents } from 'views/events/store';

interface IDeleteAction {
	id: null | string;
	index: null | number;
	delete: boolean;
	modalOpen: boolean;
}

interface IOptionsRow {
	action: () => void;
	createdAt: string;
	name: string;
	_id: string;
}

interface IPipelines {
	handleOpen: () => void;
}
const pageLimitComplex = 10;

export const Pipelines: FC<IPipelines> = ({ handleOpen }) => {
	const [pipelines, setPipelines] = useRecoilState(AllPipelinesState);
	const [conditionalPipelines, setConditionalPipelines] = useRecoilState(
		AllConditionalPipelinesState
	);
	const [pipelineRows, setPipelineRows] = useRecoilState(PipelinesTableRow);
	const isComplexRecipientModalOpen = useRecoilValue(IsComplexStepRecipientInviteModalOpen);
	const currentNavigationPage = useRecoilValue(OnboardingQRModalNavigateState);
	const [openView, setOpenView] = useState<boolean>(false);
	const [openConditionalView, setConditionalOpenView] =
		useState<boolean>(false);
	const [isEditable, setIsEditable] = useState<boolean>(false);
	const [selectedItem, setSelectedItem] = useState<any>();
	const navigate = useNavigate();
	const [deleteAction, setDeleteAction] = useState<IDeleteAction>({
		id: null,
		index: null,
		delete: false,
		modalOpen: false,
	});
	const [onboardingSearch, setOnboardingSearch] = useState<string>('');
	const setSelectedPipeline = useSetRecoilState(SelectedPipelineState);
	const [credentials, setCredentials] = useRecoilState(ClientsCredentialsState);
	const credentialsLoadedStatus = useRecoilValue(CredentialsLoadedState);
	const { successNotification, errorNotification, warningNotification } =
		useNotification();
	const setSelectedPipelineIdForInviteInvestor =
		useSetRecoilState(PipelineIdState);
	const setNavigate = useSetRecoilState(OnboardingFooterNavigateState);
	const setSelectedSteps = useSetRecoilState(SelectedStepsState);
	const setAddedAction = useSetRecoilState(AddedActionsState);
	const setUnOpennedConfig = useSetRecoilState(UnConfiguredStep);
	const setIsChecking = useSetRecoilState(IsConfigurationChecking);
	const [isModalOpen, setIsModal] = useRecoilState(OpenInviteSelectorModal);
	const setPipelineDetails = useSetRecoilState(SelectedPipelineDetails);
	const [pipelineDetails, setPipelineDetailsForQR] = useRecoilState(
		SelectedPipelineDetailsForQRState
	);
	const resetSelectedComplexOnboarding = useResetRecoilState(
		SelectedOnboardingAction
	);
	const businessCard = useRecoilValue(BusinessPaymentCardState);
	const setIsOnboaringQrModal = useSetRecoilState(OnboardingQRModalOpenState);
	// local state
	const [isLoadingQrList] = useState(false);
	const { isVerifiedPhone } = useRecoilValue(loginState);
	const [Dashboard, setDashboards] = useRecoilState(DashboardsAtom);
	const [isComplex, setIsComplex] = useRecoilState(
		ConditionalOnboardingFlowState
	);
	const setWelcomeNewUser = useSetRecoilState(WelcomeNewUserModalState);
	const [isQrInviteFlow, setIsQrInviteFlow] = useRecoilState(IsQrInviteState);
	const selectedDateRange = useRecoilValue(SelectedDateRangeState);
	const { intialSetup } = useComplexLayout();
	const { invitationSubscriptionCheck } = useFreePlan();

	const {
		get: getPinelines,
		data: pipelineList,
		error: pipelineListError,
		status: getPipelineStatus,
		loading: pipelinesLoading,
		isLoaded: pipelinesLoaded,
	} = useNetwork();
	const {
		remove: deletePipeline,
		remove: deleteComplexPipeline,
		loading: deletePipelineLoading,
	} = useNetwork();
	const { post: createCredentials } = useNetwork();
	const { fetchBusinessCard } = useBusinessCards();
	const { checkSubscription } = useSubscription();

	const {
		get: getComplexList,
		data: complexFlowList,
		loading: isComplexLoading,
		isLoaded: complexPipelinesLoaded,
	} = useNetwork();
	const [isLoading, setLoading] = useState(false);
	// hooks
	const { handleNext } = useOnboardingQrFlow();
	const { isAllowedToInvite } = useAllowedInvites();

	const { checkUserWritePermission } = useUserRoles();
	const pageNumber = useRecoilValue(FundPaginationState);
	const restPageNumber = useResetRecoilState(FundPaginationState);

	const [pagecount, setPageCount] = useState(0);

	const isUserPermissionWrite = useMemo(
		() => checkUserWritePermission(PERMISSION_SCREEN.Onboarding),
		[checkUserWritePermission]
	);
	const isSessionWritePermission = useMemo(
		() => checkUserWritePermission(PERMISSION_SCREEN.Sessions),
		[checkUserWritePermission]
	);
	const { endDate, startDate }: any = selectedDateRange[0];


	// Events
	const { initEvents } = useEvents();
	const eventsState = useRecoilValue(EventsState);

	const handleLinear = useCallback(
		(linearTablesearch?: string, isPagination = false) => {
			setLoading(true);
			const searchParam = linearTablesearch
				? `&search=${linearTablesearch}`
				: '';
			const url = `${API_URL.PIPELINE_USERS}?page=${linearTablesearch && !isPagination ? 1 : pageNumber
				}&limit=${pageLimitComplex}${searchParam}&startDate=${getOffDate(
					startDate,
					'start'
				)}&endDate=${getOffDate(endDate, 'end')}`;
			getPinelines(url).finally(() => {
				setLoading(false);
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[endDate, pageNumber, startDate]
	);

	const handleComplex = useCallback(
		(complexTablesearch?: string, isPagination = false) => {
			setLoading(true);
			const searchParam = complexTablesearch
				? `&search=${complexTablesearch}`
				: '';

			const url = `${API_URL.COMPLEX_FLOW}?isDeleted=false&page=${complexTablesearch && !isPagination ? 1 : pageNumber
				}&limit=${pageLimitComplex}${searchParam}&startDate=${getOffDate(
					startDate,
					'start'
				)}&endDate=${getOffDate(endDate, 'end')}`;
			getComplexList(url).finally(() => {
				setLoading(false);
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[endDate, pageNumber, startDate]
	);

	const handlePageChange = useCallback(() => {
		handleLinear();
		handleComplex();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getComplexList, getPinelines, pageNumber, endDate, startDate]);

	useEffect(() => {
		if (!conditionalPipelines.length) {
			handleComplex();
		}
		if (!onboardingSearch) {
			handlePageChange();
		}
		if (onboardingSearch) {
			handleLinear(onboardingSearch, true);
			handleComplex(onboardingSearch, true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageNumber, endDate, startDate]);

	useEffect(() => {
		fetchBusinessCard();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setIsChecking(false);
		setNavigate('complex-flow');
		setUnOpennedConfig(['default']);
		setSelectedSteps([]);
		//@avinash need to reset resetSelectedComplexOnboarding everytime when component mount
		resetSelectedComplexOnboarding();
		setAddedAction([]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigate]);

	useEffect(() => {
		restPageNumber();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [endDate, startDate, onboardingSearch]);

	useEffect(() => {
		if (!credentials.length && credentialsLoadedStatus) {
			createCredentials(`${API_URL.CREDENTIAL}`, {}).then(resp => {
				setCredentials(prev => [...prev, resp]);
			});
		}
		// eslint-disable-next-line
	}, [credentials]);

	useEffect(() => {
		let totalPagesFromPipelines = 0;
		let totalPagesFromConditionalPipelines = 0;
		if (!pipelinesLoading && !isComplexLoading) {
			if (complexFlowList && complexFlowList?.data?.[0]?.result) {
				setConditionalPipelines(complexFlowList.data[0].result);
				totalPagesFromConditionalPipelines =
					complexFlowList?.data[0]?.totalPages;
			}
			if (
				pipelineList &&
				pipelineList?.data?.[0]?.result &&
				getPipelineStatus
			) {
				setPipelines(pipelineList.data[0].result);
				totalPagesFromPipelines = pipelineList?.data[0]?.totalPages;
				setPageCount(
					totalPagesFromConditionalPipelines > totalPagesFromPipelines
						? totalPagesFromConditionalPipelines
						: totalPagesFromPipelines
				);
				return;
			}
			if (pipelineListError) {
				errorNotification('Failed to load onboarding flows.');
			}
		}
		// eslint-disable-next-line
	}, [pipelineList, pipelineListError, complexFlowList, pipelinesLoading, isComplexLoading, getPipelineStatus]);

	const combinedOnboarding = useMemo(() => {
		const linearOnboarding = pipelines.map(item => ({
			...item,
			type: 'linear',
		}));

		const complexOnboarding = conditionalPipelines.map(item => ({
			...item,
			type: 'complex',
		}));

		const combined = [...linearOnboarding, ...complexOnboarding];

		return combined;
	}, [conditionalPipelines, pipelines]);

	const handleView = useCallback(
		(item: any) => {
			setSelectedPipeline(item);
			setOpenView(true);
		},
		[setSelectedPipeline, setOpenView]
	);

	const handleDelete = useCallback(
		(item: any, index: number) => {
			setDeleteAction({
				delete: false,
				id: item._id,
				index,
				modalOpen: true,
			});
		},
		[setDeleteAction]
	);

	const handleViewStepDesign = useCallback((e: any, item: any) => {
		e.stopPropagation();
		setSelectedItem(item);
		setConditionalOpenView(true);
	}, []);

	const StepsJsx = useCallback(
		(fullStatus: any) => (
			<>
				{fullStatus &&
					fullStatus.map((key: any) => {
						return (
							<div
								className={`status__clip statusbg__pending statustct__${key}`}
								key={key}
							>
								<svg
									className="leftImg"
									width="12"
									viewBox="-1.4 -4 8 28"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path d="M0 0H6V9.16667V20H0L6 9.16667L0 0Z" />
								</svg>

								<div className="inner__status ">{actionKeyLabel[key]}</div>
								<svg
									width="12"
									viewBox="-0.6 -4 8 28"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path d="M0 0L6 9.16667L0 20V0Z" />
								</svg>
							</div>
						);
					})}
			</>
		),
		[]
	);
	const handleCloseViewModal = useCallback(() => {
		setOpenView(false);
		setIsEditable(false);
	}, []);

	// const handleCloseSubscriptionModal = useCallback(() => {
	// 	setValidInvite(false);
	// }, []);

	const removeComplexPipelines = async (pipelineId: string) => {
		const resp = await deleteComplexPipeline(
			`${API_URL.COMPLEX_FLOW}/${pipelineId}`
		);
		if (resp?._id) {
			successNotification('Onboarding Flow Deleted.');
			const filteredData = conditionalPipelines.filter(
				val => val._id !== pipelineId
			);
			setConditionalPipelines(filteredData);
		} else errorNotification(resp.message || MESSAGE.ERROR);
	};

	const deleteOnboadingFlow = async ({
		pipelineId,
		index,
		isOpen,
		value,
	}: {
		pipelineId: string;
		index: number;
		isOpen: boolean;
		value: boolean;
	}) => {
		const resp = await deletePipeline(
			`${API_URL.PIPELINE_USERS}/${pipelineId}`
		);
		if (resp?._id) {
			const DeshboardResult = JSON.parse(JSON.stringify(Dashboard));
			const count = DeshboardResult.data.summary?.onboarding[0]?.value - 1;
			DeshboardResult.data.summary.onboarding[0].value = count;
			setDashboards(DeshboardResult);
			setPipelines(prev => {
				const prevState = [...prev];
				prevState.splice(index, 1);
				return prevState;
			});
			successNotification('Onboarding Flow Deleted.');
			setDeleteAction(prev => ({
				...prev,
				delete: value,
				modalOpen: isOpen,
			}));
		} else errorNotification(resp?.message || MESSAGE.ERROR);
	};

	const handleShowModal = useCallback(
		async (isOpen: boolean, value: boolean) => {
			const { index, id: pipelineId }: any = deleteAction;
			const foundPipeline = conditionalPipelines.find(
				com => com._id === pipelineId
			);
			if (value && !foundPipeline) {
				await deleteOnboadingFlow({ pipelineId, index, isOpen, value });
			} else if (value && foundPipeline) {
				removeComplexPipelines(pipelineId);
				setDeleteAction(prev => ({
					...prev,
					delete: value,
					modalOpen: isOpen,
				}));
			} else {
				setDeleteAction(prev => ({
					...prev,
					delete: value,
					modalOpen: isOpen,
				}));
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[conditionalPipelines, deleteAction, Dashboard]
	);

	const handleCopyClientId = useCallback(
		(item: IOptionsRow) => {
			const { _id } = item ?? {};
			if (_id) {
				navigator.clipboard.writeText(_id);
				successNotification('Onboarding ID Copied.');
			}
		},
		[successNotification]
	);

	const handleInviteInvestor = useCallback(
		(event: any, item: any) => {
			event.stopPropagation();

			if (!item || !item.isInviteAllowed || !isSessionWritePermission) return;

			if (invitationSubscriptionCheck) {
				if (!checkUserWritePermission(PERMISSION_SCREEN.Billing)) {
					warningNotification('You need billing permission.');
					return;
				}
				return navigate(ROUTES.BILLING);
			}

			if (!isAllowedToInvite('onboarding')) {
				setWelcomeNewUser({
					open: true,
					type: 'Credits_Remain',
					serviceName: 'Onboarding Service',
				});
				return;
			}
			setIsComplex(item.type === 'complex' ? true : false);
			setPipelineDetails(item);
			setSelectedPipelineIdForInviteInvestor(item._id);
			setIsModal(true);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			checkSubscription,
			invitationSubscriptionCheck,
			isAllowedToInvite,
			isComplex,
			isVerifiedPhone,
			isSessionWritePermission,
		]
	);

	const handleUseFlow = useCallback(
		(item: any) => {
			setPipelineDetailsForQR(item);
			setPipelineDetails(item);
			setIsOnboaringQrModal(true);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isVerifiedPhone, businessCard?.card]
	);

	const handleCreateNewQR = useCallback(
		(event: any, item: any) => {
			event.stopPropagation();

			if (invitationSubscriptionCheck) {
				if (!checkUserWritePermission(PERMISSION_SCREEN.Billing)) {
					warningNotification('You need billing permission.');
					return;
				}
				return navigate(ROUTES.BILLING);
			}

			if (!isAllowedToInvite('onboarding')) {
				if (!isAllowedToInvite('onboarding')) {
					setWelcomeNewUser({
						open: true,
						type: 'Credits_Remain',
						serviceName: 'Onboarding Service',
					});
					return;
				}
			}

			setPipelineDetailsForQR(item);
			setPipelineDetails(item);
			/**
			 * setting if the flow is qr code flow or not @Manish
			 * */
			setIsQrInviteFlow(true);
			handleNext(item, 'table');
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			isVerifiedPhone,
			invitationSubscriptionCheck,
			businessCard?.card,
			checkSubscription,
			isAllowedToInvite,
			currentNavigationPage
		]
	);

	const handleModifyAndClone = (e: any, item: any, type: string) => {
		if (!item.isEditAllowed) {
			return;
		}
		e.stopPropagation();
		intialSetup({ ...item, type });
	};

	// Events
	useDeepCompareEffect(() => {
		if (eventsState.length || envHost === 'prod') return;
		initEvents();
	  }, [eventsState]);

	useEffect(() => {
		if (combinedOnboarding && PipelinesTable.HEADER.length) {
			const rows: IOptionsRow[] = [];
			combinedOnboarding.forEach((items, index) => {
				// TODO @avinash
				const item = {
					...items,
				};

				// Currently we don't  give the support to create QR flow if onboarding have payout fund.
				const isPayoutExist = (item?.nodes ?? []).some(
					(node: Record<string, string>) => node.type === 'payOut'
				);

				if (item) {
					let row: any = {};
					PipelinesTable.HEADER.forEach(({ format, key }) => {
						row.id = item._id;
						row.isInviteAllowed = item.isInviteAllowed;
						row.className = !item.isInviteAllowed ? 'disabled-btn' : '';
						row.createdAt = item?.createdAt;
						if (format === 'jsx' && key === 'action') {
							const value = () =>
								!isUserPermissionWrite ? (
									<></>
								) : (
									<div className="btn-group">
										<div className="btn-group w-100 space-between">
											{/* {item.type === 'complex' && (
												<i
													className="ri-information-line complex-flow-info"
													onClick={e => handleViewStepDesign(e, item)}
												/>
											)} */}

											<Tippy
												content={
													!isSessionWritePermission ? (
														<div style={{ textAlign: 'center' }}>
															{ErrorSessionsNotWrite}
														</div>
													) : (
														''
													)
												}
												disabled={isSessionWritePermission}
											>
												<div
													className={`invite_column ${!item.isInviteAllowed || !isSessionWritePermission
															? 'disabled-btn'
															: ''
														}`}
													onClick={e => handleInviteInvestor(e, item)}
												>
													Invite
												</div>
											</Tippy>

											{item.type === 'complex' && (
												<>
													<Tippy
														content={
															item.isEditAllowed
																? 'Duplicate your Onboarding Flow'
																: ''
														}
														disabled={!item.isEditAllowed}
													>
														<div
															className={`Pipelines--icons ${!item.isEditAllowed ? 'disabled-btn' : ''
																}`}
															onClick={e =>
																handleModifyAndClone(e, item, 'clone')
															}
														>
															<i className="ri-file-copy-line" />
														</div>
													</Tippy>
													<Tippy
														content={
															item.isEditAllowed
																? 'Edit your Onboarding Flow'
																: ''
														}
														disabled={!item.isEditAllowed}
													>
														<div
															className={`Pipelines--icons ${!item.isEditAllowed ? 'disabled-btn' : ''
																}`}
															onClick={e =>
																handleModifyAndClone(e, item, 'modify')
															}
														>
															<i className="ri-edit-box-line" />
														</div>
													</Tippy>
												</>
											)}

											<div className="pipeline-action-btns">
												{!isPayoutExist ? (
													<Tippy content={'Generate QR'}>
														<div className="Pipelines--icons">
															<i
																className="ri-menu-add-line"
																onClick={e => handleCreateNewQR(e, item)}
															/>
														</div>
													</Tippy>
												) : (
													<Tippy content="You are unable to generate a QR code due to an ongoing Fund Pay Out process in this flow.">
														<div className="Pipelines--inactive-icon">
															<i className="ri-menu-add-line" />
														</div>
													</Tippy>
												)}
												<div>
													<DropOptions
														editLabel="Configure"
														handleView={() => handleView(item)}
														handleDelete={() => handleDelete(item, index)}
														handleUseFlow={() => handleUseFlow(item)}
													/>
												</div>
											</div>
										</div>
									</div>
								);
							return (row[key] = value);
						}
						if (format === 'jsx' && key === 'fullStatus') {
							if (item.type === 'complex') {
								const value = () => (
									<SessionTreeGraph
										details={item}
										viewComplexModal={(e: any) => handleViewStepDesign(e, item)}
										maxNodes={item?.nodes?.length}
									/>
								);
								return (row[key] = value);
							} else {
								const value = () => (
									<>
										<div className="session-status">{StepsJsx(item[key])}</div>
									</>
								);
								return (row[key] = value);
							}
						}
						if (format === 'jsx' && key === 'nodes') {
							const value = () => (
								<OnboardingTreeGraph
									details={item}
									showOnBoardingName={false}
								/>
							);
							return (row[key] = value);
						}
						if (format === 'jsx' && key === '_id') {
							const value = () => (
								<>
									<div className="text_cell">{item[key]}</div>
									<div className="btn__Onhover">
										<IconBtn
											btnClassName="ri-file-copy-line  icon__primary icon__large"
											handleClickIcon={() => handleCopyClientId(item)}
										/>
									</div>
								</>
							);
							row = {
								...row,
								[key]: value,
								flowId: item[key] ?? '--',
							};
							return (row[key] = value);
						}
						if (key === 'name') {
							const value = () => (
								<>
									<div className="fund-name">{item[key] || '-a-'}</div>
									<div className="onboarding-data">
										{item[key] ? (
											<div>
												{getTime(item['createdAt' as string]) +
													' | ' +
													getDate(item['createdAt' as string])}
											</div>
										) : (
											'--'
										)}
									</div>
								</>
							);
							return (row[key] = value);
						}

						return (row = { ...row, [key]: item[key] });
					});
					item?.fullStatus?.forEach((status: string) => {
						row = {
							...row,
							[status]: 'pending',
							instances: item.instances ?? [],
							fullStatusData: item.fullStatus,
							type: item.type,
						};
					});
					rows.push(row);
				}
			});
			return setPipelineRows(rows);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		pipelines,
		isComplex,
		conditionalPipelines,
		businessCard?.card,
		businessCard,
		combinedOnboarding,
		isUserPermissionWrite,
		isSessionWritePermission,
	]);

	const searchOnboard = useCallback(
		(searchOnboarding: string) => {
			setOnboardingSearch(searchOnboarding.trim());
			handleLinear(searchOnboarding.trim());
			handleComplex(searchOnboarding.trim());
		},
		[handleComplex, handleLinear]
	);

	const isPipelineLoaded =
		!!pipelineRows.length || (complexPipelinesLoaded && pipelinesLoaded);
	const isPipelineLoading = isComplexLoading || pipelinesLoading;

	const pipelineColumn = useMemo(() => {
		if (!isUserPermissionWrite) {
			return PipelinesTable.HEADER.filter(
				headerItem => headerItem.key !== 'action'
			);
		}
		return PipelinesTable.HEADER;
	}, [isUserPermissionWrite]);

	const renderPipelineTable = useMemo(() => {
		if (
			!pipelineRows.length &&
			pipelinesLoaded &&
			!pipelines.length &&
			!isLoading
		) {
			setTimeout(() => {
				return (
					<NoDataAvailable
						label="Create New"
						description={'You have not created any onboarding flow yet.'}
						handleClickBtn={handleOpen}
						message={'No Onboarding Flow Available'}
						illustration="empty-pipeline.svg"
						hideBtn={!isUserPermissionWrite}
					/>
				);
			});
		}
		return (
			<>
				<ReactResponsiveTable
					tableType="pipeLine"
					isLoaded={isPipelineLoaded}
					isLoading={isPipelineLoading}
					column={pipelineColumn}
					rows={isPipelineLoading ? [] : pipelineRows}
					showSearch={true}
					showFilter={true}
					showDateFilter={true}
					height="100%"
					emptyHeight={'calc(100vh - 240px'}
					columnHandle={false}
					filterPage="pipelines"
					EmptyIllustration="empty-pipeline.svg"
					EmptyMessageHeading="No Onboarding Flow Available"
					EmptyMessageDescription="You have not created any onboarding flow yet."
					collapsible={true}
					defaultComponent={(_piplineData: any, _selectedPipelineQr: any) => {
						return (
							<OnboardingQRTable
								isLoadingQrList={isLoadingQrList}
								piplineData={_piplineData}
								selectedPipelineQr={_selectedPipelineQr}
								combinedOnboarding={combinedOnboarding}
							/>
						);
					}}
					handelRowClick={() => ({})}
					isPagination
					handlePageChange={handlePageChange}
					totaloPageCount={pagecount}
					getSearchText={searchOnboard}
				/>
			</>
		);
	}, [
		pipelineRows,
		pipelinesLoaded,
		pipelines.length,
		isLoading,
		isPipelineLoaded,
		isPipelineLoading,
		pipelineColumn,
		handlePageChange,
		pagecount,
		searchOnboard,
		handleOpen,
		isUserPermissionWrite,
		isLoadingQrList,
		combinedOnboarding,
	]);

	// const handleOnboarding = useCallback(() => {
	// 	setIsComplex(false);
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	// const handleComplex = useCallback(() => {
	// 	setIsComplex(true);
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	const handleCloseModal = () => {
		setConditionalOpenView(false);
		setSelectedItem({});
	};

	return (
		<>
			<div className={`pipeline-body-wrapper`}>
				{' '}
				{openConditionalView || isModalOpen || isQrInviteFlow
					? null
					: renderPipelineTable}
			</div>
			<Confirmation
				handleModal={handleShowModal}
				visible={deleteAction.modalOpen}
				description="You want to delete this onboarding flow?"
				label="Delete"
				title="Are you sure?"
				secondryLabel="Close"
				loading={deletePipelineLoading}
			/>

			{openView && (
				<Modal
					isOpen={openView}
					modalName="Onboarding Flow Details"
					closeModal={handleCloseViewModal}
					className="onboarding-details"
					showCloseBtn={false}
					isStopOutsideClick={false}
					title={
						<div className="onboarding-details__header">
							<div className="onboarding-details__title">
								Onboarding Flow Details
							</div>
						</div>
					}
				>
					<ViewPipeline
						handleClose={handleCloseViewModal}
						editable={isEditable}
					/>
				</Modal>
			)}
			<OnboardingInviteModal from={'onboardings'} />
			<OnboardingQRFlow />

			{openConditionalView && (
				<ComplexStepsView
					isOpen={openConditionalView}
					closeModal={handleCloseModal}
					details={selectedItem}
					isOnboardingList
				/>
			)}
			{isComplexRecipientModalOpen && (
				<ComplexStepsRecipientInviteView
					isOpen={isComplexRecipientModalOpen}
					closeModal={handleCloseModal}
					details={pipelineDetails}
					isOnboardingList
				/>
			)}
		</>
	);
};
