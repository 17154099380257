import {
	FC,
	JSXElementConstructor,
	ReactElement,
	ReactNode,
	ReactPortal,
	useCallback,
} from 'react';
import './aml-adverse-media.scss';
interface IProps {
	items: any;
}

export const AmlAdverseMediaSection: FC<IProps> = ({ items }) => {

	const openNewTab = useCallback((url: string) => {
		window.open(url, '_blank', 'noopener,noreferrer');
	  }, []);

	return (
		<div>
			{(items ?? []).map(
				(item: {
					title: any;
					description:
						| string
						| number
						| boolean
						| ReactElement<any, string | JSXElementConstructor<any>>
						| Iterable<ReactNode>
						| ReactPortal
						| null
						| undefined;
					link: string;
					publisher: string;
					url: string;
				}) => (
					<>
						<div
							key={`adverse--${item?.title + item?.publisher}`}
							className="adverse-media__wrapper"
						>
							<div className="adverse-media__heading">
								{item?.title ?? item?.publisher ?? 'N/A'}
							</div>
							{item?.description && (
								<div className="adverse-media__sub--heading">
									{item?.description}
								</div>
							)}

							{(item?.link || item?.url) && (
								<div
									onClick={() => openNewTab(item?.link ?? item?.url)}
									className="adverse-media__read-more"
								>
									Read More <i className="ri-external-link-line"></i>
								</div>
							)}
						</div>
					</>
				)
			)}
		</div>
	);
};
