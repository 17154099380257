import { FC, useCallback } from 'react';
interface IProps {
	label: string;
	value: string | string[];
	isFromAmlRisk?: boolean;
}

export const BasicSection: FC<IProps> = ({ label, value, isFromAmlRisk }) => {
	if (!label || !value || value === '-') {
		return <></>;
	}


	// eslint-disable-next-line react-hooks/rules-of-hooks
	const getClassName = useCallback((key: string) => {
		switch (key.toLowerCase()) {
			case 'high':
				return 'risk__high';
			case 'medium':
				return 'risk__medium';
			case 'low':
				return 'risk__low';
			default:
				return 'risk__low';
		}
	}, []);

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const getIconByStatus = useCallback((key: string) => {
		switch (key.toLowerCase()) {
			case 'high':
			case 'medium':
				return 'ri-error-warning-fill';
			case 'low':
				return 'ri-checkbox-circle-fill';
			default:
				return 'ri-checkbox-circle-fill';
		}
	}, []);


	const renderValue = (val: string | string[]) => {
		if (label === 'Source link' && typeof val === 'string') {
			return (
				<div className="source-link">
					<a href={val} target="_blank" rel="noreferrer">
						{val}
					</a>
				</div>
			);
		}
		if (val?.length === 1) {
			return <div>{val.toString() || '--'}</div>;
		}
		if (Array.isArray(val)) {
			if (val?.length === 0) {
				return 'N/A';
			}
			// eslint-disable-next-line react/no-array-index-key
			return val.map((item, index) => <div key={index}>{`${item}`}</div>);
		}
		if (typeof val === 'boolean') {
			return <div>{val ? 'true' : 'false'}</div>;
		}
		if(isFromAmlRisk && (val === "High" || val === "Medium" || val === "Low") ){
			return (
				<div className={`aml-risk__status__wrapper ${getClassName(val ?? '')}`}>
					<i className={getIconByStatus(val)}></i> {val}
				</div>
			);

		}
		return <div>{val?.toString()}</div>;
	};

	return (
		<div key={label} className="kyb-wrapper__details__kybInfoDetailsRow">
			<div className="kyb-wrapper__details__kybInfoDetailsRow__label">
				{label || '--'}
			</div>

			<div className="kyb-wrapper__details__kybInfoDetailsRow__value">
				<span>:</span>
				<div>{renderValue(value) || '--'}</div>
			</div>
		</div>
	);
};
