/* eslint-disable no-console */
import { IKybDetails } from 'global-stores/sessions/type';
import { FC, useMemo, useState } from 'react';
import { KybInfoHeader } from '../kyb-info-header';
import './kyb-filing.scss';
import { FilingCard } from 'views/sessions';

interface IKYBFiling {
	item: IKybDetails;
	companyId: string
}

export const KybFiling: FC<IKYBFiling> = ({ item, companyId }) => {
	const filingValue = useMemo(() => {
		const { financialDetails } = item ?? {};
		const { filingDetails } = financialDetails ?? {};
		const { value } = filingDetails ?? [];

		return value;
	}, [item]);

	const [showAllFilingDetails, setShowAllFilingDetails] = useState(false);

	const toggleShowAllMembers = () => {
		setShowAllFilingDetails(!showAllFilingDetails);
	};

	if (!filingValue?.length) {
		return <></>;
	}

	return (
		<>
			<div className="kyb-filing_header">
				<KybInfoHeader type="Filing" />
				{filingValue?.length > 4 && (
					<div onClick={toggleShowAllMembers} className="kyb-filing_view-more">
						{showAllFilingDetails ? 'View less' : 'View more'}
					</div>
				)}
			</div>

			<div className="kyb-filing_container">
				{filingValue?.map((file, index) => {
					if (index < 4 || showAllFilingDetails) {
						return (
							<FilingCard // eslint-disable-next-line react/no-array-index-key
								key={index}
								file={file}
								companyId={companyId}
							/>
						);
					} else {
						return <></>;
					}
				})}
			</div>
		</>
	);
};
