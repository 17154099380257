import { FC, memo, useCallback, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import {
	ActiveActionKeyNameState,
	PipelineMasterSteps,
	isChecqueFraudSelected,
} from 'global-stores';
import {
	ActionList,
	ConfigurableSteps,
	labelData,
} from 'views/complex-onboarding-flow/constant';
import {
	ComplexConfigFromState,
	ComplexConfigModalState,
	ComplexErrorSteps,
	ComplexFlowUnconfigedState,
	ConfigComplexNodeId,
	ConfigFormModalState,
	EdgesState,
	NodesState,
} from 'views/complex-onboarding-flow/stores/states';
import {
	AddedActionsState,
	CurrentStepState,
} from 'views/onboarding-flow';
import { ComplexSettingFormState } from 'views/pipeline';
import { AccountFormAction } from '../account-form-action';
import { AccreditationAction } from '../accreditation-action';
import { AmlAction } from '../aml';
import { FormAction } from '../form-action';
import { FundAction } from '../fund-action';
import { Biometric } from '../biometric-action';
import { KybAction } from '../kyb';
import { KybAFromAction } from '../kyb-form';
import KycAction from '../kyc-action/kyc-action';
import { ProofReadDocumentsAction } from '../proof-read-documents';
import { SignAgreemnet } from '../sign-agreement-action';
import { MODAL_TYPE, message } from 'constant';
import { OnboardingFlowBackModel } from 'components/footer-navigate-modal';
import './custom-node.scss';
import { useNotification } from 'hooks';
import { SuccessScreen } from '../success-screen';

import './custom-node.scss';
import { Event } from '../event';
export interface ICustomNode {
	id: string;
	data: {
		label: string;
		value: string;
		key: string;
		selects: any;
	};
}

interface ICustomNodeData {
	id: string;
	type: string;
	position: { x: number; y: number };
	key: string;
	updatedStyle: boolean;
	stepId: string;
	label: string;
	zIndex: number;
	width: number;
	height: number;
	selected: boolean;
	dragging: boolean;
}
interface IDeleteModal {
	loading?: boolean;
	data?: ICustomNodeData;
	show: boolean;
}

const defaultDataModal = {
	show: false,
	loading: false,
	data: undefined,
};
const CustomNode: FC<ICustomNode> = ({ data, id }) => {
	const [nodes, setNodes] = useRecoilState(NodesState);
	const pipelinesSteps = useRecoilValue(PipelineMasterSteps);
	const setActiveAction = useSetRecoilState(ActiveActionKeyNameState);
	const setRenderedObj = useSetRecoilState(CurrentStepState);
	const setAddedAction = useSetRecoilState(AddedActionsState);
	const setEdges = useSetRecoilState(EdgesState);
	const [complexSettingForm, setComplexSettingForm] = useRecoilState(
		ComplexSettingFormState
	);
	const setIsChequeFraudSelected = useSetRecoilState(isChecqueFraudSelected);
	const [complexFlowConfiged, setComplexFlowConfiged] = useRecoilState(
		ComplexFlowUnconfigedState
	);
	const setIsConfigFormModal =
		useSetRecoilState(ConfigFormModalState);
	const setIsConfigModal = useSetRecoilState(ComplexConfigModalState);
	const setComplexConfigFromState = useSetRecoilState(ComplexConfigFromState);
	const setNodeId = useSetRecoilState(ConfigComplexNodeId);
	const [modalData, setModalData] = useState<IDeleteModal>(defaultDataModal);
	const { warningNotification } = useNotification();

	// Local Variables

	const findAmlChildren = (jsonData: any[], startNodeId: any) => {
		const result: any[] = [];

		const findChildren = (nodeId: any) => {
			const children = jsonData?.filter((node: { parentId: string | any[] }) =>
				node.parentId.includes(nodeId)
			);
			for (const child of children) {
				if (child.stepId === 'aml') {
					result.push(child.id);
				}
				findChildren(child.id);
			}
		};

		findChildren(startNodeId);

		return result;
	};

	const handleShowModal = (id: string) => {
		const data = nodes.find((el: ICustomNodeData) => el.id === id);
		setModalData({ show: true, data });
	};

	const handleOnRemove = (id: string) => {
		const data = nodes.find((el: ICustomNodeData) => el.id === id);
		setModalData(prev => ({ ...prev, loading: true }));
		if (complexSettingForm?.[id]?.chequeFraud) {
			setIsChequeFraudSelected(false);
		}
		setComplexSettingForm((prev: Record<string, string>) => {
			const prevObj = structuredClone(prev);
			delete prevObj[id];
			return prevObj;
		});
		setComplexFlowConfiged((prev: any) => {
			const newArr = structuredClone(prev);
			const data = newArr.filter((el: any) => el.id !== id);
			return data;
		});

		setAddedAction(prev => {
			const prevObj = JSON.parse(JSON.stringify(prev));
			const data = prevObj?.filter((el: any) => el.nodeId !== id);
			return data;
		});

		if (data.key === 'allId') {
			const allAMlsIds = new Set(
				findAmlChildren(structuredClone(nodes), data.id)
			);

			setEdges((prev: any) => {
				const newEdges = structuredClone(prev);
				const data = newEdges.filter(
					(el: any) => !allAMlsIds.has(el.target) && !allAMlsIds.has(el.source)
				);
				return data;
			});
			setNodes((prev: any) => {
				const newNodes = structuredClone(prev);
				return newNodes.map((el: any) => {
					if (allAMlsIds.has(el.id)) {
						el['parentId'] = [];
					}
					el.parentId = el.parentId.filter((el: string) => !allAMlsIds.has(el));
					return el;
				});
			});
		}

		setEdges((prev: any) => {
			const prevObj = JSON.parse(JSON.stringify(prev));
			const data = prevObj.filter(
				(el: any) => el.source !== id && el.target !== id
			);
			return data;
		});

		setNodes((prev: any) => {
			const prevState = JSON.parse(JSON.stringify(prev));
			const data = prevState.filter((el: any) => el.id !== id);
			//removing all condition where deleted id exists
			for (let i = 0; i < prevState.length; i++) {
				let items = [];
				// if stepId is form then delete object where node matches else delete  node key only
				if (prevState[i].stepId === 'form') {
					items = prevState[i]?.conditions?.filter(
						(el: any) => el.nodeId !== id
					);
				} else {
					items = prevState[i]?.conditions?.map((el: any) => {
						if (el.nodeId === id) {
							delete el.nodeId;
							// pradeep : it will remove target key from condition when edge is removed
							if (prevState[i].stepId === 'dynamicForm') {
								delete el.target;
							}
						}
						return el;
					});
				}

				prevState[i].parentId = prevState[i].parentId?.filter(
					(el: string) => el !== id
				);
				prevState[i].conditions = items;
			}

			return data;
		});
		setModalData(prev => ({ ...prev, loading: false }));
	};

	const isFormSetting = useMemo(
		() => data?.label === ActionList.form,
		[data?.label]
	);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const props = {
		id,
		data,
	};

	const renderComponent = useMemo(() => {
		switch (data?.label) {
			case ActionList.form:
				return <FormAction {...props} />;
			case ActionList.kyc:
				return <KycAction {...props} />;
			case ActionList.kyb:
				return <KybAction {...props} />;
			case ActionList.signAgreement:
				return <SignAgreemnet {...props} />;
			case ActionList.aml:
				return <AmlAction {...props} />;
			case ActionList.fund:
				return <FundAction {...props} />;
			case ActionList.accreditation:
				return <AccreditationAction {...props} />;
			case ActionList.kybForm:
				return <KybAFromAction {...props} />;
			case 'accountForm':
				return <AccountFormAction {...props} />;
			case ActionList.proofReadDocuments:
			case ActionList.proofReading:
				return <ProofReadDocumentsAction {...props} />;
			case ActionList.authentication:
				return <Biometric {...props} />;
			case ActionList.successScreen : 
				return <SuccessScreen {...props} />	
			case ActionList.event : 
				return <Event {...props}/> 	
			default:
				return <></>;
		}
	}, [data?.label, props]);

	const handlerConfig = useCallback(
		async (key: string, id: string) => {
			const isNotConnected = nodes.some((el: { key: string; id: string, parentId: Array<any> }) =>{
				return el?.id === id && el?.key !== 'authentication' && !el.parentId.length
			});
			if (isNotConnected) warningNotification('This node is not connected to root!');
			// Shahbaaz: Set node Id in recoil because if we add multiple node than when try to open paticular node all the component render
			setNodeId(id);
			setComplexConfigFromState('INDIVIDUAL');
			// Shahbaz: fix coonfig on click setting icon
			if (isFormSetting) {
				setIsConfigFormModal(true);
				const result = pipelinesSteps.find(el => el.key === ActionList[key]);
				const renderObj: any = result?.actions && result.actions[0];
				setRenderedObj({ ...(renderObj ?? {}), id });
				return;
			}
			setComplexFlowConfiged((prev: any) => {
				const newArr = structuredClone(prev);
				return newArr.map((el: any) => {
					if (el.id === id) {
						return {
							...el,
							configed: true,
						};
					} else {
						return el;
					}
				});
			});
			const result = pipelinesSteps.find(el => {
				return el.key === ActionList[key]
			});
			const renderObj: any = result?.actions && result.actions[0];

			setRenderedObj({ ...(renderObj ?? {}), id });
			setActiveAction(key);
			setIsConfigModal(true);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			id,
			isFormSetting,
			pipelinesSteps,
			setActiveAction,
			setComplexConfigFromState,
			setComplexFlowConfiged,
			setIsConfigModal,
			setRenderedObj,
			nodes,
		]
	);

	// Arun Kumar: No need to hide configure modal for KYB
	const isHideSetting = useMemo(() => {
		return /\b(amlVerification|eventVerification|form|accountForm|kybForm|proofVerification)\b/.test(
			data?.label
		);
	}, [data?.label]);

	const isConfiged = useMemo(
		() => !!complexFlowConfiged?.find(el => el.id === id && el.configed),
		[complexFlowConfiged, id]
	);

	const showDeleteBtn = useMemo(() => {
		if (/authentication/.test(data.label)) {
			return false;
		}
		return true;
	}, [data]);
	
	const complexErrorNodes = useRecoilValue(ComplexErrorSteps);
	return (
		<div
			id="custom-node"
			className={
				complexErrorNodes.includes(id)
					? `custom-node-status-error`
					: 'custom-node-status-success'
			}
		>
			<div
				className={`custom-node__header ${
					isConfiged || !ConfigurableSteps.includes(ActionList[data.label])
						? 'configed '
						: 'unconfiged'
				}`}
			>
				<div className="custom-node__header__label">
					{labelData[data.label] ?? data.label}
				</div>
				<div className="custom-node__header__actions">
					{!isHideSetting && (
						<div
							className={`settings ${
								isConfiged ||
								!ConfigurableSteps.includes(ActionList[data.label])
									? 'settings__configured'
									: 'settings__configure'
							}`}
							onClick={() => handlerConfig(data?.label, id)}
						>
							<i className="ri-settings-5-line" />
						</div>
					)}
					{showDeleteBtn && (
						<div
							className="settings settings__delete"
							onClick={() => handleShowModal(id)}
						>
							<i className="ri-delete-bin-5-line" />
						</div>
					)}
				</div>
			</div>
			<div className="custom-node__body">{renderComponent}</div>
			{modalData.show && (
				<OnboardingFlowBackModel
					isOpen={modalData.show}
					isLoaded={modalData.loading}
					type={MODAL_TYPE.DELETE}
					handleProceedBack={() => {
						setModalData(defaultDataModal);
					}}
					description={
						'Deleting ' +
						modalData?.data?.label +
						' from the onboarding flow. ' +
						message.DELETE_DESCRIPTION
					}
					handleInput={() => {
						return modalData.data && handleOnRemove(modalData.data.id);
					}}
				/>
			)}
		</div>
	);
};

export default memo(CustomNode);
