import { Button, Confirmation, Loader, ReactResponsiveTable } from '@storybook';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import Tippy from '@tippyjs/react';

import { ReactPipelineModal } from 'components';
import {
	IUploadedDocuments,
	SignedDocsState,
	UploadedDocsState,
} from 'global-stores';
import { detectIncognito } from 'helpers';
import { useNotification } from 'hooks';
import {
	IsQrInviteState,
	userCapTableState,
} from 'views/pipelines/components/store';
import { TEMPLATE_SOURCE, useSignedDoc } from 'views/signed-doc';
import {
	ICreateTemplatePayload,
	IDocProvider,
	OnboardingStepInfoSelector,
	useSetSignDocument,
} from '../store';
import { incognitoMessage, qrHeaders, uploadedFilesColumn } from './constants';
import { NavigateToBackConfirmation } from 'components/esign-back-modal';
import { SimpliciSignIframe } from 'shared-components';
import { useWindowSize } from 'hooks/window-size';
import { debounce } from 'utils/debounce';

interface IProvider {
	provider: IDocProvider;
	uploadedFiles: any;
	// eslint-disable-next-line @typescript-eslint/ban-types
	setSelectedFiles?: Function;
}
export interface ICaptableHeader {
	label: string;
	name: string;
	type: 'text';
}

export const UploadedFiles: FC<IProvider> = ({
	provider,
	uploadedFiles,
	setSelectedFiles,
}) => {
	const [addedFiles, setAddedFiles] = useRecoilState(UploadedDocsState);
	const flowSteps = useRecoilValue(OnboardingStepInfoSelector);
	const userCapTableData = useRecoilValue(userCapTableState);
	const signedDocs = useRecoilValue(SignedDocsState);
	const isQrFlow = useRecoilValue(IsQrInviteState);
	const [signModalVisible, setSignModalVisible] = useState<boolean>(false);
	const [isIframeClosed, setIsIframeClosed] = useState(false);
	const [configuredDocId, setConfiguredDocId] = useState('');
	const [confirmation, setConfirmation] = useState(false);
	const [templateId, setTemplateId] = useState('');
	const [configDoc, setConfigDoc] = useState({
		prepareUrl: '',
		documentId: '',
	});

	// local state for show tippy
	const [showTippy, setShowTippy] = useState<{
		[key: string]: boolean;
	}>({});

	const { Title, Description } = incognitoMessage;

	const onloadCount = useRef(0);

	const { updateTemplate, deleteTemplate, createTemplate } =
		useSetSignDocument();
	const { errorNotification, successNotification } = useNotification();
	const { getTemplateStatus, deleteEsignTemplate } = useSignedDoc();
	const { innerSize } = useWindowSize();

	const handleConfigureFiles = useCallback(
		async (itemId: string, index: number) => {
			const result = await detectIncognito();
			if (result.isPrivate && provider === 'docusign') {
				setConfirmation(true);
				return;
			}
			setSignModalVisible(true);
			setConfiguredDocId(itemId);
			const docusignPayload: ICreateTemplatePayload = {
				documentId: itemId,
			};
			let headers: ICaptableHeader[] = [];
			if (!isQrFlow) {
				userCapTableData?.headers?.forEach((name: string) => {
					if (name.trim()) {
						headers.push({
							label: name,
							name,
							type: 'text', // for now all the headers that are coming through captable will have field type of text in the esign
						});
					}
				});
			} else {
				headers = qrHeaders;
			}
			const eSignpayload: ICreateTemplatePayload = {
				documentIds: [itemId],
				headers,
				configuration: {
					kyc: flowSteps.isKyc,
					kyb: flowSteps.isKyb,
					questionnaire: flowSteps.isQuestionnaire,
					kybForm: !!flowSteps.isKybForm
				},
				source: TEMPLATE_SOURCE.ONBOARDING
			};
			if (flowSteps.isQuestionnaire) {
				eSignpayload.questionaire = flowSteps.questionnaire;
			}
			if (flowSteps.isKybForm) {
				eSignpayload.kybFormTabs = flowSteps.kybFormTabs;
			}
			const param = provider === 'esign' ? '?type=esign' : '';

			const payload = provider === 'esign' ? eSignpayload : docusignPayload;
			const response = await createTemplate(payload, param);
			const { documentId, prepareUrl, templateId, documents, name } =
				response ?? {};
			if (prepareUrl) {
				const id = documents?.[0]?._id ?? documentId;
				setConfigDoc({ documentId: id, prepareUrl });
				// pradeep chaurasia : update SelectedFiles if setSelectedFiles is not undefined
				if (setSelectedFiles) {
					setSelectedFiles((prev: IUploadedDocuments) => {
						const prevState: any = structuredClone(prev);
						prevState[index].templateId = templateId;
						prevState[index].documentId = documentId;
						prevState[index].prepareUrl = prepareUrl;
						if (provider === 'esign') {
							prevState[index].name = documents?.[0]?.name ?? '';
							prevState[index].templateName = name;
						} else {
							prevState[index].templateName = prevState[index]?.name;
						}
						return prevState;
					});
				} else
					setAddedFiles(prev => {
						const prevState: any = structuredClone(prev);
						prevState[index].templateId = templateId;
						prevState[index].documentId = documentId;
						prevState[index].prepareUrl = prepareUrl;
						if (provider === 'esign') {
							prevState[index].name = documents?.[0]?.name ?? '';
							prevState[index].templateName = name;
						} else {
							prevState[index].templateName = prevState[index]?.name;
						}
						return prevState;
					});
				setTemplateId(templateId);
			} else {
				errorNotification('Failed to open file. try again later');
				setSignModalVisible(false);
			}
		},
		[
			provider,
			isQrFlow,
			flowSteps,
			createTemplate,
			userCapTableData?.headers,
			setSelectedFiles,
			setAddedFiles,
			errorNotification,
		]
	);

	const handleUpdateStatus = useCallback(() => {
		// pradeep chaurasia : update SelectedFiles if setSelectedFiles is not undefined
		if (setSelectedFiles) {
			setSelectedFiles((prev: IUploadedDocuments) => {
				const updatedValue = JSON.parse(JSON.stringify(prev));
				const foundIndex = updatedValue.findIndex(
					(state: any) => state._id === configuredDocId
				);
				if (foundIndex > -1) {
					updatedValue[foundIndex].configured = true;
				}
				return [...updatedValue];
			});
		} else
			setAddedFiles(prev => {
				const updatedValue = JSON.parse(JSON.stringify(prev));
				const foundIndex = updatedValue.findIndex(
					(state: any) => state._id === configuredDocId
				);
				if (foundIndex > -1) {
					updatedValue[foundIndex].configured = true;
				}
				return [...updatedValue];
			});

		setConfiguredDocId('');
	}, [configuredDocId, setAddedFiles, setSelectedFiles]);

	const handleSavedTemplate = useCallback(() => {
		setIsIframeClosed(true);
		setTimeout(() => {
			successNotification('Document Configuration Success');
			handleUpdateStatus();
			setIsIframeClosed(false);
			setSignModalVisible(false);
			setConfigDoc({
				prepareUrl: '',
				documentId: '',
			});
			setTemplateId('');
		}, 1000);
	}, [handleUpdateStatus, successNotification]);


	const handleDeleteFile = useCallback(
		async (item: any) => {
			// pradeep chaurasia : update SelectedFiles if setSelectedFiles is not undefined
			if (setSelectedFiles) {
				setSelectedFiles((prev: IUploadedDocuments[]) => {
					const prevState = [...prev];
					const fileIndex = prevState?.findIndex(
						el => el._id === item?._id && el.node === item.node
					);
					prevState.splice(fileIndex, 1);
					return prevState;
				});
			}
			setAddedFiles(prev => {
				const prevState = [...prev];
				const fileIndex = prevState?.findIndex(
					el => el._id === item?._id && el.node === item.node
				);
				prevState.splice(fileIndex, 1);
				return prevState;
			});
			successNotification('File Deleted Successfully.');
			setConfigDoc({
				prepareUrl: '',
				documentId: '',
			});
			if (item.type === 'newUpload') {
				await deleteTemplate(provider, item._id ?? item.templateId);
			}
		},
		[
			deleteTemplate,
			provider,
			setAddedFiles,
			setSelectedFiles,
			successNotification,
		]
	);

	const handleCheckbox = useCallback(
		(event: any, item: { _id: string; node: string }) => {
			event.stopPropagation();
			const { checked } = event.target;
			// pradeep chaurasia : update SelectedFiles if setSelectedFiles is not undefined
			if (setSelectedFiles) {
				setSelectedFiles((preState: IUploadedDocuments[]) => {
					const fileIndex = preState?.findIndex(
						el => el._id === item?._id && el.node === item.node
					);
					const data = [...preState].map((file, idx) => {
						if (idx === fileIndex) {
							return {
								...file,
								isChecked: checked,
							};
						}
						return file;
					});
					return data;
				});
			}
			setAddedFiles(preState => {
				const fileIndex = preState?.findIndex(
					el => el._id === item?._id && el.node === item.node
				);
				const data = [...preState].map((file, idx) => {
					if (idx === fileIndex) {
						return {
							...file,
							isChecked: checked,
						};
					}
					return file;
				});
				return data;
			});
		},
		[setAddedFiles, setSelectedFiles]
	);

	const handleReConfigureDoc = useCallback(
		async (item: any) => {
			const result = await detectIncognito();
			if (result.isPrivate && provider === 'docusign') {
				setConfirmation(true);
				return;
			}
			const { documentId, prepareUrl, templateId } = item;
			setSignModalVisible(true);
			setConfiguredDocId(templateId);
			setTemplateId(templateId);
			const eSignpayload: ICreateTemplatePayload = {
				headers: !isQrFlow
					? userCapTableData?.headers?.map((name: string) => ({
							label: name,
							name,
							type: 'text', // for now all the headers that are coming through captable will have field type of text in the esign
					  })) ?? []
					: qrHeaders,
				configuration: {
					kyc: flowSteps.isKyc,
					kyb: flowSteps.isKyb,
					questionnaire: flowSteps.isQuestionnaire,
					kybForm: !!flowSteps.isKybForm
				},
			};

			if (flowSteps.isQuestionnaire) {
				eSignpayload.questionaire = flowSteps.questionnaire;
			}
			if (flowSteps.isKybForm) {
				eSignpayload.kybFormTabs = flowSteps.kybFormTabs;
			}

			const data = await updateTemplate(
				eSignpayload,
				templateId,
				flowSteps.isKyc
			);
			if (data) {
				const newPrepareUrl = prepareUrl.replace(templateId, data._id);
				
				setTemplateId(data._id);
				if(setSelectedFiles) 
					setSelectedFiles((prev: IUploadedDocuments) => {
						const updatedValue = JSON.parse(JSON.stringify(prev));
						const foundIndex = updatedValue.findIndex(
							(state: any) => state.templateId === templateId
							);
						if (foundIndex > -1) {
							updatedValue[foundIndex].templateName = data.name;
							updatedValue[foundIndex].templateId = data._id;
							updatedValue[foundIndex]._id = data._id;
							updatedValue[foundIndex].prepareUrl = newPrepareUrl;
							updatedValue[foundIndex].updatedAt = data.updatedAt;
						}
						return [...updatedValue];
					});
				setConfigDoc({ documentId, prepareUrl: newPrepareUrl });
			} else {
				setConfigDoc({ documentId: '', prepareUrl: '' });
				setSignModalVisible(false);
				errorNotification('Failed to reconfigure the document.');
			}
		},
		[
			provider,
			isQrFlow,
			userCapTableData,
			updateTemplate,
			flowSteps,
			errorNotification,
			setSelectedFiles
		]
	);

	const configureButtons = useCallback(
		(item: any, index: number) => {
			if (item.configured && provider === 'esign') {
				return (
					<Button
						handleClick={() => handleReConfigureDoc(item)}
						label="Re Configure"
						type="button__ghost--primary button__small UploadedFiles--btn-height"
					/>
				);
			}
			if (!item.configured) {
				return (
					<Button
						handleClick={() => handleConfigureFiles(item._id, index)}
						label="Configure"
						type="button__ghost--primary button__small UploadedFiles--btn-height"
					/>
				);
			}
			return (
				<Button
					disabled
					label="Configured"
					type="button__ghost--primary button__small UploadedFiles--btn-height"
				/>
			);
		},
		[handleConfigureFiles, handleReConfigureDoc, provider]
	);

	const handleCloseIframe = useCallback(async () => {
		if (provider !== 'esign') {
			setSignModalVisible(false);
			onloadCount.current = 0;
			return;
		}
		setSignModalVisible(false);
		const status = await getTemplateStatus(templateId);
		if (status === 'created' || status === 'decline') {
			setTimeout(() => {
				deleteEsignTemplate(templateId);
				setTemplateId('');
			}, 1000);
		}
		setConfigDoc({ documentId: '', prepareUrl: '' });
	}, [deleteEsignTemplate, getTemplateStatus, provider, templateId]);

	const files = useMemo(() => {
		const rows: any[] = [];
		(uploadedFiles ?? addedFiles).forEach((item: any, index: number) => {
			if (item) {
				let row: any = {};
				uploadedFilesColumn.forEach(({ format, key }) => {
					if (format === 'jsx' && key === 'actions') {
						const value = () => {
							return (
								<div className="files-action-btn">
									{configureButtons(item, index)}
									<Button
										handleClick={() => handleDeleteFile(item)}
										label="Remove"
										type="button__ghost--danger button__small UploadedFiles--btn-height"
									/>
								</div>
							);
						};
						return (row[key] = value);
					}
					if (format === 'jsx' && key === 'checkbox') {
						const value = () => (
							<input
								className="files-select-btn"
								type={'checkbox'}
								onClick={e => handleCheckbox(e, item)}
								checked={item.isChecked}
							/>
						);
						return (row[key] = value);
					}
					if (format === 'jsx' && key === 'name') {
						const value = () => (
							<Tippy
								disabled={!showTippy[`uploaded-files-${item?._id}-${index}`]}
								content={<div className='uploaded-files_tippy'>{item[key]}</div>}
							>
								<div
									className="uploaded-files_doc-name"
									id={`uploaded-files-${item?._id}-${index}`}
								>
									{item[key] ?? '--'}
								</div>
							</Tippy>
						);
						return (row[key] = value);
					}
					return (row = { ...row, [key]: item[key] });
				});
				rows.push(row);
			}
		});
		return rows;
	}, [
		addedFiles,
		configureButtons,
		handleCheckbox,
		handleDeleteFile,
		showTippy,
		uploadedFiles,
	]);

	const updateTooltipVisibility = useCallback(() => {
		// Select all elements with the class '.uploaded-files_doc-name'
		document.querySelectorAll('.uploaded-files_doc-name').forEach(cell => {
		  // Update the visibility of the tooltip if the content is overflowed
		  setShowTippy((prev: any) => ({
			...prev,
			[cell.id]: cell.scrollWidth > cell.clientWidth, // Tooltip shows if content is truncated
		  }));
		});
	  }, []);
	  
	useEffect(() => {

		// Debounce the update to avoid frequent execution during window resize
		const debouncedUpdate = debounce(updateTooltipVisibility, 500);

		// Trigger the debounced function
		debouncedUpdate();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [innerSize, uploadedFiles, addedFiles]);
	  
	const renderBoldSignIframe = () => {
		return (
			<div className="iframe-container">
				{configDoc.prepareUrl ? (
					<div className="iframe-container__loader-wrapper">
						<div
							className="iframe-container__display-hidden"
						>
							<Loader dimension={60} className="loader-blue" />
						</div>
						<SimpliciSignIframe
							signUrl={configDoc.prepareUrl}
							className="iframe doc-sign-modal-wrapper"
							handleSubmitModal={handleSavedTemplate}
						/>						
						<button onClick={handleCloseIframe} className="close-btn">
							<i className="ri-close-line"></i>
						</button>
					</div>
				) : (
					<Loader className="loader-blue" dimension={60} />
				)}
			</div>
		);
	};

	const renderModalBody = () => {
		return !isIframeClosed ? (
			renderBoldSignIframe()
		) : (
			<Loader className="loader-blue" dimension={60} />
		);
	};

	const updateNames = useCallback(
		(selectedDocs: IUploadedDocuments[]) => {
			const updatedArray = selectedDocs.map(obj => {
				const matchingItem = signedDocs.find(
					item => item.templateId === obj.templateId
				);
				if (matchingItem) {
					if (provider === 'esign') {
						return {
							...obj,
							templateName: matchingItem.name,
							name: matchingItem.documents?.[0]?.name ?? '',
						};
					}
					return {
						...obj,
						name: obj.name || matchingItem?.documentName,
						templateName: obj.templateName || matchingItem?.documentName,
					};
				}
				return obj;
			});
			return updatedArray;
		},
		[provider, signedDocs]
	);

	useEffect(() => {
		// pradeep chaurasia : update SelectedFiles if setSelectedFiles is not undefined
		if (setSelectedFiles) {
			setSelectedFiles((prev: IUploadedDocuments[]) => {
				const clonedPrev = structuredClone(prev);
				const updatedVal = updateNames(clonedPrev);
				return updatedVal;
			});
		} else
			setAddedFiles(prev => {
				const clonedPrev = structuredClone(prev);
				const updatedVal = updateNames(clonedPrev);
				return updatedVal;
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [signedDocs]);

	return (
		<div className="uploaded-files">
			{files?.length > 0 && (
				<ReactResponsiveTable
					tableType="agreementdocumentmodal"
					isLoading={false}
					isLoaded={true}
					rows={files}
					column={uploadedFilesColumn}
					height={'calc(100vh - 536px)'}
					emptyHeight={'calc(100vh - 400px'}
					handelRowClick={() => () => ({})}
					uploadedFiles={uploadedFiles}
				/>
			)}
			<Confirmation
				visible={confirmation}
				title={Title}
				description={''}
				boldDescription={Description}
				handleModal={() => setConfirmation(false)}
				label="Close"
			/>
			<ReactPipelineModal
				body={renderModalBody()}
				visible={signModalVisible}
				showFooter={false}
				showHeader={false}
				isFullScreen={false}
			/>
			{signModalVisible && (
				<NavigateToBackConfirmation
					isModalOpen={signModalVisible}
					modalCloseHandler={handleCloseIframe}
				/>
			)}			
		</div>
	);
};
