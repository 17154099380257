import { FC } from 'react';
import { camelCaseToCapitalizedWords } from 'utils';
import { BasicSection } from 'views';

interface IProps {
	items: any[];
	isDataModified: boolean;
	isLineBetween?: boolean;
}

export const AmlWatcherBasicSection: FC<IProps> = ({
	items,
	isDataModified,
	isLineBetween,
}) => {
	return (
		<>
			{!isDataModified &&
				(items ?? []).map(item => (
					<div key={`aml-basic-${item?.id}-${item?.key}`}>
						<BasicSection
							key={item?.key}
							label={camelCaseToCapitalizedWords(item?.key)}
							value={item?.value ?? 'N/A'}
						/>
					</div>
				))}
			{isDataModified && (
				<>
					{(items ?? []).map((item, index) => (
						<>
							{Object.keys(item).map(key => (
								<BasicSection
									key={`basic-section--${key}`}
									label={camelCaseToCapitalizedWords(key)}
									value={item[key] ?? 'N/A'}
								/>
							))}
							{index + 1 !== items?.length && isLineBetween && (
								<div className="separator-line"></div>
							)}
						</>
					))}
				</>
			)}
		</>
	);
};
