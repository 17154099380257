import React, { useCallback } from 'react';
import { Loader, Tooltip } from '@storybook';
import { camelCaseToCapitalizedWords } from 'utils';
import { useKybFilingFile } from 'views/sessions';

type Filing = {
	uid?: string;
	title?: string;
	source?: string;
	fileRequested?: boolean;
	price?: string;
	priceWithoutServiceCharges?: string;
	serviceCharges?: string;
	category?: string;
	deliverTime?: string;
	isFixedPaidDocument?: boolean;
	ownDocumentAvailable?: {
		Id: string;
		uid: string;
		fileId: string;
		submittedAt?: string;
		requestedAt?: string;
	};
	download?: boolean;
	error?: boolean;
};

interface FilingCardProps {
	file: Filing;
	companyId: string;
}

export const FilingCard: React.FC<FilingCardProps> = ({ file, companyId }) => {
	const { ownDocumentAvailable, title } = file ?? {};
	const { getFile, loading, fileStatus } = useKybFilingFile();

	const formatDate = useCallback((dateString: string) => {
		const date = new Date(dateString);
		if (isNaN(date.getTime())) return 'N/A';
		return date?.toLocaleDateString();
	}, []);

	return (
		<div className="kyb-filing_container__card">
			<div className="kyb-filing_container__card__initial">
				<div className="kyb-filing_container__card__initial__text">
					{title ? camelCaseToCapitalizedWords(title) : '--'}
				</div>
				<div className="kyb-filing_container__card__initial__designation">
					Date: {formatDate(ownDocumentAvailable?.submittedAt ?? '') || 'N/A'}
				</div>
			</div>
			<Tooltip
				text={
					fileStatus.length !== 0 && fileStatus !== 'completed'
						? camelCaseToCapitalizedWords(fileStatus)
						: file?.deliverTime ?? 'Download'
				}
				direction="top"
			>
				<div
					className={`kyb-filing_container__card__para ${
						fileStatus.length !== 0 && fileStatus !== 'completed'
							? 'kyb-filing_container__disable'
							: ''
					}`}
				>
					{loading ? (
						<Loader type="loader" dimension={14} />
					) : (
						<i
							className="ri-download-line"
							onClick={() => getFile(file, companyId)}
						/>
					)}
				</div>
			</Tooltip>
		</div>
	);
};
