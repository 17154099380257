import { FC } from 'react';
import { IRiskView } from 'global-stores/sessions/type';
import { camelCaseToCapitalizedWords } from 'utils';
import { BasicSection, KybInfoHeader } from 'views';
interface IProps {
	riskData: IRiskView;
}

export const AmlRiskAnalysis: FC<IProps> = ({ riskData }) => {
	if (Object.keys(riskData ?? {})?.length === 0) {
		return <></>;
	}
	return (
		<div key={Math.floor(Math.random() * 10000) + 1}>
			<KybInfoHeader type={'Risk View'} />
			<div key={Math.floor(Math.random() * 10000) + 1}>
				{Object.entries(riskData ?? {}).map(([key, value]) => (
					<div key={`risk-view--${key + Math.floor(Math.random() * 10000) + 1}`}>
						<div className="aml-view-more-heading aml-risk-heading">
							{camelCaseToCapitalizedWords(key)}
						</div>
						{value &&
							typeof value === 'object' &&
							Object.keys(value).map((item, index) =>
								item === 'riskScores' ? (
									<>
										<div key={`${item + index +  Math.floor(Math.random() * 10000) + 1}`} className="AMLWatcherDetails--sanctions__country">
											<div className="AMLWatcherDetails--sanctions__label">
												{camelCaseToCapitalizedWords(item)}
											</div>
											<div>:</div>
											<div className="AMLWatcherDetails--sanctions__data">
												{Object.entries(
													value[item] as Record<string, string | number>
												).map(([key, value]) => (
													<div key={`risk-sub--view--${key + index +  Math.floor(Math.random() * 10000) + 1}`}>
														{`${camelCaseToCapitalizedWords(key)}( ${value} )`}
													</div>
												))}
											</div>
										</div>
									</>
								) : (
									<BasicSection
										key={`risk-basic--view--${item + index +  Math.floor(Math.random() * 10000) + 1}`}
										label={camelCaseToCapitalizedWords(item)}
										value={value?.[item]}
										isFromAmlRisk={true}
									/>
								)
							)}
					</div>
				))}
			</div>
		</div>
	);
};
