import { useCallback, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import axios from 'axios';

import { API_URL, MESSAGE } from 'constant';
import { loginState, WebTokenState } from 'global-stores';
import { REACT_APP_API_HOST as API_HOST } from 'envs';
import { useCookie, useNotification } from 'hooks';
import { useRetryOperation } from 'utils';

import './mock-api';

interface IConfig {
	headers?: {
		Authorization?: string;
		'Content-Type'?: string;
		'Cache-Control'?: string;
		accountMode?: string;
	};
	signal?: AbortSignal;
}
interface IProps {
	updateState?: boolean;
	returnResponse?: boolean;
}

export const useNetwork = (
	{ updateState = true, returnResponse = false }: IProps = {
		updateState: true,
		returnResponse: false,
	}
) => {
	const { accessToken: token } = useRecoilValue(loginState);
	const { deleteCookie } = useCookie();
	const { errorNotification } = useNotification();
	const { retryOperation } = useRetryOperation();

	const { get: getCookie } = useCookie();
	const localUserData = getCookie('user');
	const webToken = useRecoilValue(WebTokenState);
	const accessToken =
		webToken?.length > 0 ? webToken : token || localUserData?.accessToken;
	const [data, setData] = useState<any>(null);
	const [error, setError] = useState<any>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [status, setStatus] = useState<any>(null);
	const [isLoaded, setIsLoaded] = useState<boolean>(false);
	const [response, setResponse] = useState<any>(null);
	const [cancelTokenSource, setCancelTokenSource] = useState<any>();
	const [fileUploading, setFileOploading] = useState(0);
	const { sandboxStatus } = useRecoilValue(loginState);
	const sandbox = sandboxStatus ?? localUserData?.sandboxStatus;
	const config: IConfig = useMemo(() => ({}), []);
	const postConfig: IConfig = useMemo(() => ({}), []);

	// pradeep : AbortController implemented for aborting running api call if needed
	const abortController = new AbortController();
	const { signal } = abortController ?? {};

	if (accessToken) {
		config.headers = {
			Authorization: `Bearer ${accessToken}`,
			accountMode: sandbox ? 'sandbox' : 'live',
		};
	}
	config.headers = {
		...(config.headers ?? {}),
		'Cache-Control': 'no-cache',
	};
	config.signal = signal;

	postConfig.headers = {
		'Content-Type': 'application/json',
		'Cache-Control': 'no-cache',
		...(config.headers ?? {}),
	};

	/* this function is use to stop the file uploading process,
	   by this it will cancel the ongoing file upload Api call. */
	const stopUploadingFile = useCallback(() => {
		if (cancelTokenSource) {
			cancelTokenSource.cancel('file Uploading cancelled');
		}
	}, [cancelTokenSource]);

	const get = useCallback(
		async (url: string, configHeader?: any): Promise<any> => {
			setLoading(true);
			try {
				const response = await retryOperation(
					() =>
					 	axios.get(
							API_HOST + url,
							configHeader ?? config
						)
				);
				const { data: apiPayload } = response;
				if (returnResponse) {
					return { response, apiPayload };
				}
				if (updateState) {
					setStatus(response?.status);
					setIsLoaded(true);
					setData(apiPayload);
				}
				return apiPayload;
			} catch (err: any) {
				// if (err?.response?.status === 401 || err?.response?.status === 403) {
				// 	await moveToLoginPage();
				// 	deleteCookie('user');
				// 	deleteCookie('userEmail');
				// 	deleteRefreshCookie("auth_refresh_token");
				// 	localStorage.clear();
				// 	window.location.href = '/login';
				// }
				if (returnResponse) {
					return {
						errorCode: err?.response?.status ?? 500 | 400,
						errorData: err?.response?.data ?? {
							message: MESSAGE.ERROR,
						},
					};
				}
				if (updateState) {
					setError(err);
				}
				// shahbaaz: hide toaster becasue initially user don't have compliance Data
				if (
					url === API_URL.BUSINESS_COMPLIANCE &&
					err?.response?.status === 400
				) {
					return;
				}

				if (err?.response?.status) {
                    const errorMessage = err?.response?.data?.message?.toLowerCase();
                    if (errorMessage?.includes('logged in required') || errorMessage?.includes('Unauthorized user.')) {
                        return null;
                    } else {
                        // Default behavior for other errors
                        errorNotification(
                            `${err.response.status}: ${errorMessage ?? MESSAGE.ERROR}`
                        );
                    }
				}
				return null;
			} finally {
				if (updateState) {
					setLoading(false);
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[config, errorNotification, returnResponse, updateState]
	);

	const post = useCallback(
		async (url: string, requestJSON: any, configHeader?: any) => {
			if (configHeader) {
				postConfig.headers = {
					...postConfig.headers,
					...configHeader,
				};
			}
			if (updateState) {
				setLoading(true);
			}
			try {
				const response = await retryOperation(
					() =>
					 	axios.post(
							API_HOST + url, requestJSON, {
								...postConfig,
							}
						),
				);
				const { data: apiPayload } = response;
				setResponse(response);
				if (response.status === 500) {
					if (updateState) {
						setError(response.type);
					}
				}
				if (updateState) {
					setStatus(response?.ok);
				}
				if (url.includes('charts?businessId')) {
					if (updateState) {
						setIsLoaded(true);
						setData(apiPayload);
					}
					return apiPayload;
				}
				const apiResponse = apiPayload.data ?? apiPayload;

				if (updateState) {
					setIsLoaded(true);
					setData(apiResponse);
				}
				return apiResponse;
			} catch (err: any) {
				// if (err?.response?.status === 401 || err?.response?.status === 403) {
				// 	await moveToLoginPage();
				// 	deleteCookie('user');
				// 	deleteCookie('userEmail');
				// 	deleteRefreshCookie("auth_refresh_token");
				// 	localStorage.clear();
				// 	window.location.href = '/login';
				// }
				if (updateState) {
					setError(err);
				}
				if (returnResponse) {
					return {
						errorCode: err?.response?.status ?? 500 | 400,
						errorData: err?.response?.data ?? {
							message: MESSAGE.ERROR,
						},
					};
				}
				if (err?.response?.status && err?.response?.data) {
                    const errorMessage = err.response.data.message;
                    // Skip showing the notification if the message contains "logged in required"
                    if (errorMessage?.includes('logged in required') || errorMessage?.includes('Unauthorized user.')) {
                        return;
                    }
                    errorNotification(`${err.response.data.message ?? MESSAGE.ERROR}`);
                }
				return null;
			} finally {
				if (updateState) {
					setLoading(false);
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[updateState, postConfig, returnResponse, errorNotification]
	);

	const formData = useCallback(
		async (url: string, requestJSON: any) => {
			setLoading(true);
			try {
				const response = await fetch(API_HOST + url, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${accessToken}`,
						accountMode: sandbox ? 'sandbox' : 'live',
					},
					body: requestJSON,
				});
				const apiData = await response.json();
				if (returnResponse) {
					return { response, apiData };
				}
				if (updateState) {
					setStatus(response?.ok);
					setIsLoaded(true);
					setData(apiData);
					setLoading(false);
				}
				return apiData;
			} catch (err: any) {
				// if (err?.response?.status === 401 || err?.response?.status === 403) {
				// 	await moveToLoginPage();
				// 	deleteCookie('user');
				// 	deleteCookie('userEmail');
				// 	deleteRefreshCookie("auth_refresh_token");
				// 	localStorage.clear();
				// 	window.location.href = '/login';
				// }
				if (returnResponse) {
					return {
						errorCode: err?.response?.status ?? 500 | 400,
						errorData: err?.response?.data ?? {
							message: MESSAGE.ERROR,
						},
					};
				}
				if (updateState) {
					setError(err);
				}
				return null;
			} finally {
				if (updateState) {
					setLoading(false);
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[accessToken, returnResponse, sandbox, updateState]
	);

	const formDataWithAxios = useCallback(
		async (url: string, requestJSON: any) => {
			const source = axios.CancelToken.source();
			setCancelTokenSource(source);
			try {
				const response = await axios.post(
					API_HOST + url, requestJSON,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`,
						},
						cancelToken: source.token,
						onUploadProgress: (data: any) => {
							setFileOploading(Math.round((100 * data.loaded) / data.total));
						},
					}
				);
				const apiData = await response;
				if (returnResponse) {
					return { response, apiData };
				}
				if (updateState) {
					setStatus(response?.ok);
					setIsLoaded(true);
					setData(apiData);
					setLoading(false);
				}
				return apiData;
			} catch (err: any) {
				// if (err?.response?.status === 401 || err?.response?.status === 403) {
				// 	await moveToLoginPage();
				// 	deleteCookie('user');
				// 	deleteCookie('userEmail');
				// 	deleteRefreshCookie("auth_refresh_token");
				// 	localStorage.clear();
				// 	window.location.href = '/login';
				// }
				if (returnResponse) {
					return {
						errorCode: err?.response?.status ?? 500 | 400,
						errorData: err?.response?.data ?? {
							message: MESSAGE.ERROR,
						},
					};
				}
				if (updateState) {
					setError(err);
				}
				return null;
			} finally {
				if (updateState) {
					setFileOploading(0);
					setLoading(false);
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[accessToken, returnResponse, updateState]
	);

	const put = useCallback(
		async (url: string, requestJSON?: any) => {
			try {
				const response = await retryOperation(
					() => fetch(API_HOST + url, {
						method: 'PUT',
						...postConfig,
						body: JSON.stringify(requestJSON),
					}
						)
				);
				if (updateState) {
					setStatus(response?.ok);
				}
				const apiData = await response.json();
				if (updateState) {
					setStatus(response.status);
					setIsLoaded(true);
					setData(apiData.data);
				}
				return apiData.data;
			} catch (err: any) {
				// if (err?.response?.status === 401 || err?.response?.status === 403) {
				// 	await moveToLoginPage();
				// 	deleteCookie('user');
				// 	deleteCookie('userEmail');
				// 	deleteRefreshCookie("auth_refresh_token");
				// 	localStorage.clear();
				// 	window.location.href = '/login';
				// }

				if (updateState) {
					setError(err);
				}
				return null;
			} finally {
				if (updateState) {
					setLoading(false);
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[deleteCookie, postConfig, updateState]
	);

	const remove = useCallback(
		async (url: string, requestJSON?: any) => {
			setLoading(true);
			try {
				const response = await retryOperation(
					() => fetch(API_HOST + url, {
						method: 'DELETE',
						...postConfig,
						body: JSON.stringify(requestJSON),
					})
				);
				if (updateState) {
					setStatus(response?.ok);
				}
				const apiData = await response.json();
				const { data: apiPayload } = apiData ?? {};
				if (returnResponse) {
					return { response: apiData, apiPayload };
				}
				if (updateState) {
					setStatus(response.status);
					setIsLoaded(true);
					setData(apiData.data);
				}
				return apiData.data;
			} catch (err: any) {
				// if (err?.response?.status === 401 || err?.response?.status === 403) {
				// 	await moveToLoginPage();
				// 	deleteCookie('user');
				// 	deleteCookie('userEmail');
				// 	deleteRefreshCookie("auth_refresh_token");
				// 	localStorage.clear();
				// 	window.location.href = '/login';
				// }

				if (updateState) {
					setError(err);
				}
				if (returnResponse) {
					return {
						errorCode: err?.response?.status ?? 500 | 400,
						errorData: err?.response?.data ?? {
							message: MESSAGE.ERROR,
						},
					};
				}
				return null;
			} finally {
				if (updateState) {
					setLoading(false);
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[postConfig, updateState]
	);

	const patch = useCallback(
		async (url: string, requestJSON?: any) => {
			if (updateState) {
				setLoading(true);
			}
			try {
				const response = await retryOperation(
					() => fetch(API_HOST + url, {
						method: 'PATCH',
						...postConfig,
						body: JSON.stringify(requestJSON),
					})
				);
				if (updateState) {
					setStatus(response?.ok);
				}
				const apiData = await response.json();
				if (updateState) {
					setIsLoaded(true);
				}
				const apiResponse = apiData.data ?? apiData;
				if (updateState) {
					setData(apiResponse);
				}
				return apiData.data ?? apiData;
			} catch (err: any) {
				// if (err?.response?.status === 401 || err?.response?.status === 403) {
				// 	await moveToLoginPage();
				// 	deleteCookie('user');
				// 	deleteCookie('userEmail');
				// 	deleteRefreshCookie("auth_refresh_token");
				// 	localStorage.clear();
				// 	window.location.href = '/login';
				// }
				if (updateState) {
					setError(err);
				}
				return null;
			} finally {
				if (updateState) {
					setLoading(false);
				}
			}
		},
		// eslint-disable-next-line
		[postConfig, accessToken]
	);

	return {
		get,
		post,
		formData,
		put,
		data,
		status,
		error,
		loading,
		setLoading,
		remove,
		patch,
		isLoaded,
		setIsLoaded,
		response,
		accessToken,
		abortController,
		sandbox,
		formDataWithAxios,
		fileUploading,
		stopUploadingFile,
	};
};
