import { useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import {
	subscriptionServiceState,
	UserRolesPermissions,
	UserSubscriptionsState,
} from 'global-stores';
import { CANCEL_SUBSCRIPTION_STEPS } from '../../constants';
import {
	CancelSubscriptionActiveStepState,
	CancelSubscriptionDueAmountState,
	CancelSubscriptionResponseState,
	CancelSubscriptionTextState,
} from '../states';
import {
	CancelSubscriptionConfirmation,
	CancelSubscriptionCompleted,
	CancelSubscriptionProcessing,
	CancelSubscriptionDues,
	CancelSubscriptionPaymentSuccess,
} from '../../components';
import { CostBreakdownDetailState, ICostBreakdownDetails } from 'views';

export const useCancelSubscription = () => {
	// Accessing the current user's role and the subscription cancellation response
	const userRole = useRecoilValue(UserRolesPermissions);
	const { cancelledAt = '' } = useRecoilValue(CancelSubscriptionResponseState);

	// State management for active step, subscription details, and reset functions
	const [activeStep, setActiveStep] = useRecoilState(
		CancelSubscriptionActiveStepState
	);
	const [subscription, setSubscription] = useRecoilState(
		subscriptionServiceState
	);
	const dueAmount = useRecoilValue(CancelSubscriptionDueAmountState);
	const resetConfirmationTxt = useResetRecoilState(CancelSubscriptionTextState);
	const resetDueAmount = useResetRecoilState(CancelSubscriptionDueAmountState);
	const resetCancelResponse = useResetRecoilState(
		CancelSubscriptionResponseState
	);
	const resetUserSubscriptions = useResetRecoilState(UserSubscriptionsState);
	const setCostBreakdown = useSetRecoilState(CostBreakdownDetailState);

	// Close modal and reset states
	const handleCloseModal = useCallback(() => {
		// Resetting subscription if cancelled, and clearing other related states
		if (cancelledAt) {
			setSubscription({});
			resetUserSubscriptions();
			setCostBreakdown((prev: ICostBreakdownDetails) => ({
				...prev,
				total: prev?.total ? prev?.total - (dueAmount ?? 0) : 0,
			}));
		}
		setActiveStep(CANCEL_SUBSCRIPTION_STEPS.DEFAULT);
		resetConfirmationTxt();
		resetDueAmount();
		resetCancelResponse();
	}, [
		cancelledAt,
		resetCancelResponse,
		resetDueAmount,
		setActiveStep,
		setSubscription,
		resetConfirmationTxt,
		resetUserSubscriptions,
		dueAmount,
		setCostBreakdown,
	]);

	// Open the cancellation modal and set the active step to confirmation
	const handleOpenModal = useCallback(() => {
		setActiveStep(CANCEL_SUBSCRIPTION_STEPS.CONFIRMATION);
	}, [setActiveStep]);

	// Generate modal props dynamically based on the active step
	const modalProps = useMemo(() => {
		const commonProps = {
			isOpen: true,
			modalName: 'Cancel_Subscription',
			closeOnEscBtn: false, // Prevent closing the modal with the ESC key
			isStopOutsideClick: false, // Allow clicks outside the modal to close it
			className: 'Cancel_Subscription', // Main class for styling
			optionalClassName: '', // Optional additional classes
			closeModal: handleCloseModal, // Close modal handler
		};

		// Return specific modal configuration based on the current step
		switch (activeStep) {
			case CANCEL_SUBSCRIPTION_STEPS.CONFIRMATION:
				return {
					...commonProps,
					showCloseBtn: false, // hide close button
					children: (
						<CancelSubscriptionConfirmation handleClose={handleCloseModal} />
					),
				};
			case CANCEL_SUBSCRIPTION_STEPS.PROCESSING:
				return {
					...commonProps,
					showCloseBtn: false, // Hide close button during processing
					children: (
						<CancelSubscriptionProcessing message="Processing your request" />
					),
				};
			case CANCEL_SUBSCRIPTION_STEPS.COMPLETED:
				return {
					...commonProps,
					children: (
						<CancelSubscriptionCompleted handleClose={handleCloseModal} />
					),
				};
			case CANCEL_SUBSCRIPTION_STEPS.SUCCESS:
				return {
					...commonProps,
					showCloseBtn: true,
					children: <CancelSubscriptionPaymentSuccess />,
				};
			case CANCEL_SUBSCRIPTION_STEPS.DUES:
				return {
					...commonProps,
					children: <CancelSubscriptionDues />,
				};
			case CANCEL_SUBSCRIPTION_STEPS.PAYMENT_PROCESSING:
				return {
					...commonProps,
					showCloseBtn: false, // Hide close button during payment processing
					children: (
						<CancelSubscriptionProcessing message="Processing your payment" />
					),
				};
			default:
				return {
					isOpen: false, // Close modal if no valid step
					modalName: '',
					children: <></>, // Empty children
				};
		}
	}, [activeStep, handleCloseModal]); // Recompute only when activeStep or handleCloseModal changes

	// Determine if the cancel subscription button should be shown based on user role and subscription availability
	const showCancelSubscription = useMemo(() => {
		return !!(
			subscription?.id && // Ensure subscription ID exists
			(userRole?.name?.toLowerCase() === 'owner')
		);
	}, [subscription?.id, userRole?.name]);

	return {
		modalProps, // Modal properties to pass to the modal component
		setActiveStep, // Function to update the active step
		handleOpenModal, // Function to open the modal with confirmation step
		showCancelSubscription, // Boolean to show the cancel subscription button based on user role
	};
};
